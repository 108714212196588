<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>分类：</span>
            <el-select v-model="pageInfo.questionTypeId"
                       class="m-2"
                       placeholder="选择分类"
                       size="large">
              <el-option
                  v-for="item in classificationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div class="marginLeft15">
            <span>题干：</span>
            <input v-model="pageInfo.title" placeholder="题干" class="searchInput" />
          </div>
          <div class="marginLeft15">
            <span>分值：</span>
            <input v-model="pageInfo.score"
                   placeholder="分值"
                   class="searchInput"
                   @blur="floatParse(pageInfo.score, val => pageInfo.score = val)"/>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">题目列表</div>
          <div class="flex">
            <button class="addBtn marginLR10" @click="drawer = true">组卷预览</button>
          </div>
        </div>
        <el-table :data="tableData"
                  ref="multipleTableRef"
                  border
                  row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" :reserve-selection="true"/>
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="title" label="题目" />
          <el-table-column prop="score" label="分值" />
          <el-table-column prop="type" label="题目类型">
            <template #default="{row}">
              <span>{{ row.type === 0 ? '单选' : '其他'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="录入时间" />
          <!--<el-table-column fixed="right" label="操作" width="150">-->
          <!--  <template #default="scope">&lt;!&ndash; scope.$index 表示 序列号 0、1、2 &ndash;&gt;-->
          <!--    <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">添加到试卷</el-button>-->
          <!--  </template>-->
          <!--</el-table-column>-->
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-drawer v-model="drawer"
               :size="700"
               :with-header="false">
      <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
        <div class="fontSize20gay text-left">组卷</div>
        <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
          <div style="width: 600px;margin: auto">
            <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
              <el-form-item label="试卷名称:" size="large" label-width="120px">
                <div> {{ examInfo && examInfo.name }} </div>
              </el-form-item>
              <el-form-item label="已选题目:" prop="paperQuestions" size="large" label-width="120px">
                <div style="width: 100%;height: 410px;overflow-y: scroll;">
                  <div class="flex justify-end">
                    <el-button size="mini"
                               type="primary"
                               class="marginLR10"
                               style="border: none;color: #ffffff;background-color: #3769b9;"
                               @click="removeColumn(null)">清空已选</el-button>
                  </div>
                  <el-table :data="pageForm.paperQuestions" style="width: 100%">
                    <el-table-column label="序号" width="90">
                      <template #default="scope">
                        <!--<div>{{ scope.$index + 1 }}</div>-->
                        <div>
                          <el-input v-model="pageForm.paperQuestions[scope.$index].sort"
                                    maxlength="3"
                                    max="999"
                                    @blur="integerParse(pageForm.paperQuestions[scope.$index].sort, val => pageForm.paperQuestions[scope.$index].sort = val)"></el-input>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="title" label="题目" />
                    <el-table-column prop="score" label="分值" width="80" />
                    <el-table-column label="操作" align="center" width="100">
                      <template #default="scope">
                        <el-button link type="primary"
                                   style="color: #3769B9"
                                   @click="removeColumn(scope.row, scope.$index)">删除选择</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-form-item>
              <el-form-item label="合格分数:" size="large" label-width="120px">
                <div> {{ examInfo && examInfo.score }} </div>
              </el-form-item>
              <el-form-item label="总分:" size="large" label-width="120px">
                <div> {{ totalScore }} </div>
              </el-form-item>
              <el-form-item label="出题方式:" size="large" label-width="120px">
                <div> {{ examInfo && examInfo.order == 1 ? '顺序出题' : '乱序出题' }} </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="marginTop15px">
          <button class="subBtn" @click="submit">提交</button>
          <button class="qxBtn" @click="drawer = false">继续添加</button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  editPaperQuestion,
  getAllQuestionType,
  getQuestionPage,
  queryPaper,
  queryPaperQuestionAll
} from "@/api/examination";
import {floatParse, integerParse} from "@/utils/common";

class HandlerQueue {
  constructor() {
    this.queueList = []
  }

  add(e){
    this.queueList.push(e)
  }

  update(){
    if (this.queueList.length > 0) {
      const failList = []
      this.queueList.forEach((handler) => {
        handler(
            // 执行成功
            () => {

            },
            // 执行失败
            () => {
              failList.push(handler)
            }
        )
      })
      this.queueList = failList
    }
  }
}
export default {
  name: "examPaperAdd",
  data(){
    return{
      tableData:[],
      Info:null,
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        questionTypeId:'',
        title:'',
        score: '',
      },
      exportIds:'',
      // ------------------------------------
      // 已选题目列表选中状态 -----更新----> 题目列表
      handlerQueue: new HandlerQueue(),
      // 已回显
      isEcho: false,
      // 当前添加的id
      id: null,
      // 试卷详情
      examInfo: null,
      // 抽屉
      drawer: false,
      // 筛选分类
      classificationOptions: [],
      // 组卷信息
      pageForm: {
        // 所选题目
        paperQuestions: [],
      },
      pageFormRules: {
        paperQuestions: [
          {required: true, message: '请添加题目', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    totalScore(){
      return this.pageForm.paperQuestions.reduce((prev, cur) => prev + cur.score, 0)
    }
  },
  mounted() {
    this.getList()
    this.getOption()
    this.id = this.$route.params.id
    if (this.id) {
      this.queryPaperInfo()
    }
  },
  methods:{
    floatParse,
    integerParse,
    /**
     * 提交试卷
     */
    submit(){
      const sortValidate = () => {
        const find = function (arr, val){
          const res = []
          arr.forEach((iter, index) => {
            if (iter === val) {
              res.push(index)
            }
          })
          return res
        }
        //
        const errors = []
        // 题目序号
        const sortArr = []
        this.pageForm.paperQuestions.forEach(iter => {
          sortArr.push(iter.sort)
        })
        //
        for (let i = 0; i < sortArr.length; i++) {
          const iter = sortArr[i]
          const res = find(sortArr, iter)
          if (res.length > 1) {
            res.forEach(i => {
              errors.push(this.pageForm.paperQuestions[i])
            })
            break
          }
        }
        return errors
      }

      const errs = sortValidate()
      if (errs.length > 0) {
        const res = []
        errs.forEach(iter => res.push(iter.sort))
        this.$root.ElMessage.error(`题目序号 ${res.toString()} 重复`)
        return
      }

      /**
       * 参数拼接
       */
      const paramsAssemble = () => {
        const submitData = {
          // 试卷id
          paperId: this.id,
          // 题目
          questions: []
        }

        this.pageForm.paperQuestions.forEach(iter => {
          submitData.questions.push({
            // 题目id
            questionId: iter.id,
            // 题目排序
            sort: iter.sort
          })
        })

        return submitData
      }

      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          editPaperQuestion(paramsAssemble()).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.$root.useRouter.replace('/examPaper/list')
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 查询试卷信息
     */
    queryPaperInfo(){
      // 查询试卷信息
      queryPaper(this.id).then(res => {
        if (res.success) {
          this.examInfo = res.data
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    /**
     * 选择组卷题目
     */
    handleSelectionChange(list){
      this.pageForm.paperQuestions = list
      this.pageForm.paperQuestions.map((iter, index) => {
        if (!Reflect.has(iter, 'sort')) {
          iter.sort = index + 1
        }
      })
    },
    /**
     * 移除选中
     */
    removeColumn(row, innerIndex){
      this.$nextTick(() => {
        if (row === null) {
          this.$refs.multipleTableRef.clearSelection()
          return
        }
        // 取消选中
        const index = this.tableData.findIndex(iter => iter.id === row.id)
        if (index !== -1) {
          this.$refs.multipleTableRef.toggleRowSelection(this.tableData[index], undefined)
        } else {
          // 加入待处理 触发时机: 列表更新 触发失败|触发成功
          const that = this
          this.handlerQueue.add((success, fail) => {
            this.$nextTick(() => {
              const index = that.tableData.findIndex(iter => iter.id === row.id)
              if (index !== -1) {
                that.$refs.multipleTableRef.toggleRowSelection(that.tableData[index], undefined)
                success()
              } else {
                fail()
              }
            })
          })
          // 从 this.pageForm.paperQuestions 移除
          this.pageForm.paperQuestions.splice(innerIndex, 1)
        }
      })
    },
    /**
     * 获取分类
     */
    getOption(){
      getAllQuestionType().then(res => {
        res.data.forEach(iter => {
          this.classificationOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const questionTypeId = ''
      const title = ''
      const score =  ''
      this.pageInfo = { ...this.pageInfo, questionTypeId, title, score }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getQuestionPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;

        // 编辑回显
        if (!this.isEcho) {
          queryPaperQuestionAll({ paperId: this.id }).then(res => {
            if (res.success) {
              res.data.forEach(iter => {
                const index = this.tableData.findIndex(row => row.id === iter.question.id)
                if (index !== -1) {
                  this.$nextTick(() => {
                    this.tableData[index].sort = iter.sort
                    this.$refs.multipleTableRef.toggleRowSelection(this.tableData[index], undefined)
                  })
                }
              })
            } else {
              this.$root.ElMessage.error(res.message);
            }
            this.isEcho = true
          })
        }
        // 表格异常回显
        this.handlerQueue.update()
      })
    },
    showEdit(row){

    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>